import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-sterling-heights-michigan.png'
import image0 from "../../images/cities/scale-model-of-airtime-trampoline-&-game-park-in-sterling-heights-michigan.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Sterling Heights'
            state='Michigan'
            image={image}
            lat='42.5812'
            lon='-83.0303'
            attractions={ [{"name": "AirTime Trampoline & Game Park", "vicinity": "662 E Big Beaver Rd, Troy", "types": ["point_of_interest", "establishment"], "lat": 42.5608704, "lng": -83.13439949999997}] }
            attractionImages={ {"AirTime Trampoline & Game Park":image0,} }
       />);
  }
}